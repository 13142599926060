import client from './client'

const fetchCompanyList = () => client.get(`/fetchCompanyList`, { 'Access-Control-Allow-Origin': '*' })

const fetchCompanyList_4Users = (UserID) => client.get(`/fetchCompanyList_4Users`, { UserID }, { 'Access-Control-Allow-Origin': '*' })

const fetchRoleList = () => client.get(`/fetchRoleList`, { 'Access-Control-Allow-Origin': '*' })

const fetchRoleList_4Users = () => client.get(`/fetchRoleList_4Users`, { 'Access-Control-Allow-Origin': '*' })

const get_Consignee = (coTin, item) => client.get(`/get_Consignee`, { coTin, item }, { 'Access-Control-Allow-Origin': '*' })

const get_Trucks = (coTin, newinput) => client.get(`/get_Trucks`, { coTin, newinput }, { 'Access-Control-Allow-Origin': '*' })

const get_Places = (coTin, newinput) => client.get(`/get_Places`, { coTin, newinput }, { 'Access-Control-Allow-Origin': '*' })

const fetch_Places = () => client.get(`/fetch_Places`, { 'Access-Control-Allow-Origin': '*' })

const getPlaces = (coTin) => client.get(`/getPlaces`, { coTin }, { 'Access-Control-Allow-Origin': '*' })

const postFO = (remark, startDate, orderDeliveryAgreementDate, consignee, container, commodity, fo, truck, grossWeight, tariff, origin, destination, orderGenerator, coTin, UserID) => client.post(`/postFO`, { remark, startDate, orderDeliveryAgreementDate, consignee, container, commodity, fo, truck, grossWeight, tariff, origin, destination, orderGenerator, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getFOForView = (coTin) => client.get(`/getFOForView`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const getFOForTruckHistory = (coTin, item) => client.get(`/getFOForTruckHistory`, { params: { coTin, item } }, { 'Access-Control-Allow-Origin': '*' })

const searchFOForView = (coTin, newinput) => client.get(`/searchFOForView`, { params: { coTin, newinput } }, { 'Access-Control-Allow-Origin': '*' })

const updateFO = (oldfo, fo, sDate, dDate, consignee, truck, grossWeight, tariff, origin, commodity, container, destination, orderGenerator, remark, coTin, UserID) => client.put(`/updateFoWeb`, { oldfo, fo, sDate, dDate, consignee, truck, grossWeight, tariff, origin, commodity, container, destination, orderGenerator, remark, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const deleteFO = (idfreightOrder, coTin, UserID) => client.put(`/deleteFO`, { idfreightOrder, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const postAppointment = (fo, appDate, appRemark, coTin, UserID, djiboutiWaybill, mojoIn, gelanIn, cometIn, mekeleIn, combolchaIn, diredewaIn, interchangeOut, foDoc, galafiLetter, interchangeIn, mojoOut, gelanOut, cometOut, mekeleOut, combolchaOut, diredewaOut, gcsDjibouti) => client.put(`/postAppointment`, { fo, appDate, appRemark, coTin, UserID, djiboutiWaybill, mojoIn, gelanIn, cometIn, mekeleIn, combolchaIn, diredewaIn, interchangeOut, foDoc, galafiLetter, interchangeIn, mojoOut, gelanOut, cometOut, mekeleOut, combolchaOut, diredewaOut, gcsDjibouti }, { 'Access-Control-Allow-Origin': '*' })

const getAppointments = (coTin) => client.get(`/getAppointments`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const getAppointmentsForExpense = (coTin) => client.get(`/getAppointmentsForExpense`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deleteAppointment = (freightID, coTin, UserID) => client.put(`/deleteAppointment`, { freightID, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const postExpense = (fo, amount, voucher, reason, coTin, UserID) => client.post(`/postExpense`, { fo, amount, voucher, reason, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const postExpenseWithCompleteStatus = (fo, amount, voucher, reason, coTin, UserID) => client.post(`/postExpenseWithCompleteStatus`, { fo, amount, voucher, reason, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getExpensesPosted = (coTin) => client.get(`/getExpensesPosted`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deleteExpense = (id_op, coTin, UserID) => client.put(`/deleteExpense`, { id_op, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const deleteExpenseAndCommission = (op_fo,id_op, id_com, coTin, UserID) => client.put(`/deleteExpenseAndCommission`, { op_fo,id_op, id_com, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const searchExpensePostedWithDate = (coTin, searchingCustomer, fromDate, toDate) => client.get(`/_searchExpensePostedWithDate`, { params: { coTin, searchingCustomer, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const searchExpensePostedWithJustDate = (coTin, fromDate, toDate) => client.get(`/searchExpensePostedWithJustDate`, { params: { coTin, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const searchExpensesPosted = (coTin, input) => client.get(`/searchExpensesPosted`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })

const searchAppointment = (coTin, input) => client.get(`/searchAppointment`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })


const postLoan = (fo, amount, voucher, reason, coTin, UserID) => client.post(`/postLoan`, { fo, amount, voucher, reason, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getLoanPosted = (coTin) => client.get(`/getLoanPosted`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deleteLoan = (idfreightOrder,id_op, coTin, UserID) => client.put(`/deleteLoan`, { idfreightOrder,id_op, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const searchLoanPosted = (coTin, input) => client.get(`/searchLoanPosted`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })

//-----------------
const postPenality = (foWithError, foDeductFrom, liableBody, amount, reason, truck, coTin, UserID) => client.post(`/postPenality`, { foWithError, foDeductFrom, liableBody, amount, reason, truck, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getPenalityPosted = (coTin) => client.get(`/getPenalityPosted`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deletePenality = (pen_fo, id_pen, coTin, UserID) => client.put(`/deletePenality`, { pen_fo, id_pen, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const searchPenalityPosted = (coTin, input) => client.get(`/searchPenalityPosted`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })

const updatePenality = (newid_pen, newfoWithError, newfoDeductFrom, newliableBody, newamount, newreason, newtruck, coTin, UserID) => client.put(`/updatePenality`, { newid_pen, newfoWithError, newfoDeductFrom, newliableBody, newamount, newreason, newtruck, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

//---------------------------
const getFreightForInvoice = (coTin) => client.get(`/getFreightForInvoice`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const postNewInvoice = (invoice, customer, coTin, UserID) => client.post(`/postNewInvoice`, { invoice, customer, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getInvoices = (coTin) => client.get(`/getInvoices`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deleteInvoice = (invoiceId, coTin, UserID) => client.put(`/deleteInvoice`, { invoiceId, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const addToInvoice = (FOAddToInvoice, amountAddToInvoice, invoiceid, coTin, UserID) => client.post(`/addToInvoice`, { FOAddToInvoice, amountAddToInvoice, invoiceid, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })


const getInvoiceItems = (invoiceid, coTin) => client.get(`/getInvoiceItems`, { params: { invoiceid, coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deleteInvoiceItems = (item, coTin, UserID) => client.put(`/deleteInvoiceItems`, { item, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const searchFOForInvoiceWithDate = (coTin, searchingFO, searchingCustomer, fromDate, toDate) => client.get(`/searchFOForInvoiceWithDate`, { params: { coTin, searchingFO, searchingCustomer, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const searchFOForInvoice = (coTin, searchingFO, searchingCustomer) => client.get(`/searchFOForInvoice`, { params: { coTin, searchingFO, searchingCustomer } }, { 'Access-Control-Allow-Origin': '*' })

//-------------------------
const postIncome = (fo, amount, voucher, reason, coTin, UserID) => client.post(`/postIncome`, { fo, amount, voucher, reason, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getIncomePosted = (coTin) => client.get(`/getIncomePosted`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const deleteIncome = (idfreightOrder, id_ip, coTin, UserID) => client.put(`/deleteIncome`, { idfreightOrder, id_ip, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const searchIncomePostedonDates = (coTin, fromDate, toDate) => client.get(`/searchIncomePostedonDates`, { params: { coTin, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const searchIncomePosted = (coTin, input) => client.get(`/searchIncomePosted`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })

const searchIncomeInputWdates = (coTin, input, fromDate, toDate) => client.get(`/searchIncomeInputWdates`, { params: { coTin, input, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const getUnpaidFOforIncome = (coTin) => client.get(`/getUnpaidFOforIncome`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const searchReceivables = (coTin, input) => client.get(`/searchReceivables`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })
//-------------------------
const postCommission = (fo, amount, voucher, reason, coTin, UserID) => client.post(`/postCommission`, { fo, amount, voucher, reason, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

const getCommissions = (coTin) => client.get(`/getCommissions`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const searchCommission = (coTin, input) => client.get(`/searchCommission`, { params: { coTin, input } }, { 'Access-Control-Allow-Origin': '*' })

const searchCommissionWithDate = (coTin, searchingCustomer, fromDate, toDate) => client.get(`/searchCommissionWithDate`, { params: { coTin, searchingCustomer, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const searchCommissionWithJustDate = (coTin, fromDate, toDate) => client.get(`/searchCommissionWithJustDate`, { params: { coTin, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const deleteCommission = (id_com, coTin, UserID) => client.put(`/deleteCommission`, { id_com, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

//-----New Cost and Commission mashup-------
const postCostandCommissionWithCompleteStatus = (fo, name, transportCost,amount, commission, voucher, reason, coTin, UserID) => client.post(`/postCostandCommissionWithCompleteStatus`, { fo, name, transportCost,amount, commission, voucher, reason, coTin, UserID}, { 'Access-Control-Allow-Origin': '*' })

const getCombinedView = (coTin) => client.get(`/getCombinedView`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

const getGps = (coTin) => client.get(`/getGpsData`, { params: { coTin } }, { 'Access-Control-Allow-Origin': '*' })

//-----Freight order report in freight screen
const searchFOPostedWithDate = (coTin, searchingCustomer, fromDate, toDate) => client.get(`/searchFOPostedWithDate`, { params: { coTin, searchingCustomer, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

const searchFOPostedWithJustDate = (coTin, fromDate, toDate) => client.get(`/searchFOPostedWithJustDate`, { params: { coTin, fromDate, toDate } }, { 'Access-Control-Allow-Origin': '*' })

//-----Manage screen-------
const getPlacesForView = (coTin) => client.get(`/getPlacesForView`, {params:{coTin}},{'Access-Control-Allow-Origin': '*'})

const postPlaces = (placeName, coTin, UserID) => client.post(`/postPlaces`, { placeName, coTin, UserID }, { 'Access-Control-Allow-Origin': '*' })

export default {
    fetchRoleList,
    fetchCompanyList,
    fetchRoleList_4Users,
    fetchCompanyList_4Users,
    get_Consignee,
    get_Trucks,
    get_Places,
    fetch_Places,
    getPlaces,
    postFO,
    getFOForView,
    getFOForTruckHistory,
    searchFOForView,
    searchExpensePostedWithDate,
    searchExpensePostedWithJustDate,
    updateFO,
    deleteFO,
    postAppointment,
    getAppointments,
    getAppointmentsForExpense,
    deleteAppointment,
    postExpense,
    postExpenseWithCompleteStatus,
    getExpensesPosted,
    deleteExpense,
    deleteExpenseAndCommission,
    searchExpensesPosted,
    searchAppointment,
    postLoan,
    getLoanPosted,
    deleteLoan,
    searchLoanPosted,
    postPenality,
    getPenalityPosted,
    deletePenality,
    searchPenalityPosted,
    updatePenality,
    getFreightForInvoice,
    postNewInvoice,
    getInvoices,
    deleteInvoice,
    searchFOForInvoice,
    searchFOForInvoiceWithDate,
    addToInvoice,
    getInvoiceItems,
    deleteInvoiceItems,
    postIncome,
    getIncomePosted,
    deleteIncome,
    searchIncomePosted,
    searchIncomePostedonDates,
    getUnpaidFOforIncome,
    searchIncomeInputWdates,
    searchReceivables,
    getCommissions,
    postCommission,
    searchCommission,
    searchCommissionWithDate,
    searchCommissionWithJustDate,
    deleteCommission,
    postCostandCommissionWithCompleteStatus,
    getCombinedView,
    getGps,
    searchFOPostedWithDate,
    searchFOPostedWithJustDate,
    getPlacesForView,
    postPlaces
}

import React from 'react';
import { Text, StyleSheet, TextInput, TouchableOpacity, View, Linking } from 'react-native';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function FOLister({ onFOpress, onUserpress, onPlatePress, iconText, qelem, employee_name, topRightIcon, topRightIconButton, freightOrderNo,freightOrderAddedDate, commodity, billOfLoading, orderDeparture, orderDeliveryAgreementDate, originPlace, destinationPlace, orderStatus, truck_plate, orderGenerator, trailer_plate, orderContainer, driver_name, middleName, phone_addis, phone_djibouti, orderWeight, orderTariff, orderTotalTitle, orderPay, paymentForTruck, paymentForCompany, cus_name, remark, cus_phone, cus_email, id_pen, pen_from, incoming_payment_made, pen_reason, pen_amount, pen_ErrorFoundedFO }) {
    const insertNewlines = (text, chunkSize) => {
        let result = '';
        for (let i = 0; i < text.length; i += chunkSize) {
            result += text.substr(i, chunkSize) + '\n';
        }
        return result;
    };
    return (
        <View style={[styles.FOrderContainer, { backgroundColor: qelem }]}>
            <View style={styles.FOheaderContainer}>
                <TouchableOpacity style={styles.FOheaderContainer} onPress={onFOpress}>
                    <Text style={styles.FoHeaderText}>FO: {freightOrderNo}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.FOheaderContainer} onPress={onUserpress}>
                    <Text style={styles.FoHeaderText}>{employee_name}  {freightOrderAddedDate}</Text>
                </TouchableOpacity>
                {/* <TouchableOpacity style={styles.FOheaderContainer} onPress={onFOpress}>
                    <Text style={styles.FoHeaderText}></Text>
                </TouchableOpacity> */}
                {topRightIconButton ?
                    <View>
                        <TouchableOpacity onPress={topRightIconButton}>
                            <View style={styles.FoHeaderIcon}>
                                {topRightIcon}
                                <Text style={styles.FoHeaderText}>{iconText}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                : 
                null}
            </View>

            <View style={styles.orderContainer}>
                <View style={styles.dateTimeContainer}>
                    <View style={styles.startLocationContainer}>
                        <Text style={styles.dateTimeText}>{orderDeparture}</Text>
                    </View >
                    <View style={styles.endLocationContainer}>
                        <Text style={styles.dateTimeText}>{orderDeliveryAgreementDate}</Text>
                    </View >
                </View>
                <View style={styles.toFromContainer}>
                    <View style={styles.startLocationContainer}>
                        <Text style={styles.toFromText}>{originPlace}</Text>
                    </View >
                    <View style={styles.line} />
                    <View style={styles.IconContainer} >
                        <MaterialCommunityIcons name="truck-delivery-outline" size={24} color="green" />
                        <Text style={styles.OrderStatus}>{orderStatus}</Text>
                    </View>
                    <View style={styles.line} />
                    <View style={styles.endLocationContainer}>
                        <Text style={styles.toFromText}>{destinationPlace}</Text>
                    </View >
                </View>

                <View style={styles.cargoContainer1}>

                    <View style={styles.cargoDetail}>
                        <View ><Text style={styles.labelText}>Container</Text></View>
                        <View ><Text style={styles.mainTextLong}>{orderContainer ? <>{insertNewlines(orderContainer, 13)}</> : '     -'}</Text></View>
                    </View>

                    {orderTariff ? <View style={styles.cargoDetail}>
                        <View ><Text style={styles.labelText}>Tariff</Text></View>
                        <View ><Text style={styles.mainText}>{orderTariff}</Text></View>
                    </View>
                        : null}
                    {commodity ?
                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Commodity</Text></View>
                            <View ><Text style={styles.mainText}>{commodity}</Text></View>
                        </View>
                        : null}
                    {/*<View style={styles.cargoDetail}>
                        <View ><Text style={styles.labelText}>Bill Of Loading</Text></View>
                        <View ><Text style={styles.mainText}>{billOfLoading}</Text></View>
                    </View>*/}
                </View>

                {id_pen ?
                    <View style={styles.cargoContainer}>
                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>From</Text></View>
                            <View ><Text style={styles.mainText}>{pen_from}</Text></View>
                        </View>
                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Deduct</Text></View>
                            <View ><Text style={styles.mainText}>{pen_amount}</Text></View>
                        </View>
                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Reason</Text></View>
                            <View ><Text style={styles.mainText}>{pen_reason}</Text></View>
                        </View>
                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Error On FO</Text></View>
                            <View ><Text style={styles.mainText}>{pen_ErrorFoundedFO}</Text></View>
                        </View>
                    </View>
                    : null}
                <View style={styles.lineSeparator} />
                <View style={styles.TruckAndDriverContainer}>
                    <View style={styles.cargoContainer}>

                        <TouchableOpacity style={styles.cargoDetail} onPress={onPlatePress}>
                            <View ><Text style={styles.labelText}>Plate</Text></View>
                            <View ><Text style={styles.mainText}>{truck_plate}</Text></View>
                        </TouchableOpacity>
                        {/* <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Trailer Plate</Text></View>
                            <View ><Text style={styles.mainText}>{trailer_plate}</Text></View>
                        </View> */}

                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Work Origin</Text></View>
                            <View ><Text style={styles.mainText}>{orderGenerator}</Text></View>
                        </View>

                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Consignee</Text></View>
                            <View ><Text style={styles.mainText}>{cus_name}</Text></View>
                        </View>
                        {/* <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Driver</Text></View>
                            <View ><Text style={styles.mainText} >{driver_name}</Text></View>
                        </View> */}
                        {/* <View style={styles.cargoDetail}>
                            <View><Text style={styles.labelText}>Phone</Text></View>
                            <View ><Text style={styles.mainText} onPress={() => Linking.openURL(`tel:${phone_addis}`)}>{phone_addis}</Text></View>
                        </View> */}
                        {/* <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Phone</Text></View>
                            <View ><Text style={styles.mainText} onPress={() => Linking.openURL(`tel:${phone_djibouti}`)}>{phone_djibouti}</Text></View>
                        </View> */}
                    </View>
                </View>
                <View style={styles.lineSeparator}>
                </View>
                {remark ?
                    <View style={styles.cargoContainer}>
                        <View style={styles.cargoDetail}>
                            <View ><Text style={styles.labelText}>Remark</Text></View>
                            <View ><Text style={styles.mainText}>{remark}</Text></View>
                        </View>
                    </View>
                    : null}

            </View>
            <View style={styles.PaymentContainer}></View>
            <View style={styles.customerContainer}></View>
        </View>
    )
}

const styles = StyleSheet.create({
    FOrderContainer: {
        borderRadius: 5,
        borderWidth: .3,
        backgroundColor: colors.ForeGroundWhite,
        shadowColor: colors.primary,
        elevation: 5,
        marginBottom: 10,
        flexDirection: 'column',
        width: '97%',
        alignSelf: 'center',
        paddingHorizontal: 2,
        paddingBottom: 5
    },
    FOheaderContainer: {
        height: 35,
        // paddingVertical: 3,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    FoHeaderText: {
        fontSize: 14,
        fontWeight: '600',
    },
    FoHeaderIcon: {
        marginRight: 6,
        flexDirection: 'row'
    },
    orderContainer: {
        backgroundColor: colors.white,
        paddingHorizontal: 3
    },
    dateTimeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    line: {
        flex: 1,
        height: 1,
        backgroundColor: 'green',
        marginHorizontal: 8,
    },
    lineSeparator: {
        flex: 1,
        height: 1,
        backgroundColor: colors.Grey,
        marginHorizontal: 10,
    },
    IconContainer: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    OrderStatus: {
        fontSize: 12,
        fontWeight: '600'
    },
    toFromContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 5
    },
    startLocationContainer: {},
    endLocationContainer: {

    },
    dateTimeText: {
        fontSize: 14,
        fontWeight: '400'
    },
    toFromText: {
        fontSize: 17,
        fontWeight: '800'
    },
    cargoContainer1: {
        flexDirection: 'row',
        //backgroundColor: 'orange',
        justifyContent: 'space-between',
        paddingTop: 5,
        paddingBottom: 5
    },
    cargoContainer: {
        flexDirection: 'row',
        //backgroundColor: 'orange',
        justifyContent: 'space-between',
        paddingTop: 2,
        paddingBottom: 5
    },
    cargoDetail: {
        flexDirection: 'column',
    },
    labelText: {
        fontSize: 12,
        fontWeight: '400',
        color: colors.Grey,
        alignSelf: 'center'
    },
    mainText: {
        fontSize: 15,
        fontWeight: '700',
        color: colors.Charcoal,
    },
    mainTextLong: {
        fontSize: 15,
        fontWeight: '700',
        color: colors.Charcoal,
    },
    cargoDetailTwo: {
        flexDirection: 'row'
    },
    TruckAndDriverContainer: {
        // backgroundColor: colors.yellow,
        marginVertical: 5
    },
    PaymentContainer: {
        // backgroundColor: colors.green,
    },
    customerContainer: {
        backgroundColor: colors.Charcoal
    },

})
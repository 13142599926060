import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, ScrollView, TouchableOpacity, Text, Picker, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';

import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather, MaterialIcons, AntDesign } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
// import { BannerAd, BannerAdSize } from 'react-native-google-mobile-ads';
import Dashboard from './Dashboard';
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import * as XLSX from 'xlsx';
import FormattedDateNoHR from '../components/FormattedDateNoHR';
import FormattedDateFancy from '../components/FormattedDateFancy';
import FormattedDateFormal from '../components/FormattedDateFormal';
import FormattedDateSmall from '../components/FormattedDateSmall';
import CustomAlert from '../components/CustomAlert';
import OrderModal from './OrderModal';
import ItemLister from '../components/ItemLister';

const SCREEN_HEIGHT = Dimensions.get('window').height;

export default function Manage(){

    const { user,logout, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)
    
    const UserID = user.userId
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    useEffect(() => {
        const timeoutId = setInterval(() => { }, 5000);
        getPlaces()
        return () => clearInterval(timeoutId)
    })
    const PlaceInputRef = useRef('');

    const[addingPlace,setaddingPlace] = useState(false)
    const[locations,setLocations] = useState(false)
    const[placeName,setPlaceName] = useState(null)

    const submitPlace = async() => {
        if(!placeName){
            window.alert("Please add place name")
        }else{
            try{
                setaddingPlace(true)
                const response = await services.postPlaces(placeName, coTin, UserID)
                if(response.status === 200){
                    setaddingPlace(false)
                    getPlaces()
                    PlaceInputRef.current.clear()
                    window.alert("New location added!")
                }else{
                    window.alert("An error occured, please try again later")
                    setaddingPlace(false)
                }
            }catch(error){
                window.alert("An error occured, please try again later")
                setaddingPlace(false)
            }
        }
    }

    const getPlaces = async () =>{
        try{
            const response = await services.getPlacesForView(coTin)
            if(response.status === 200){
                setLocations(response.data)
            } else {
                console.log(`Error: ${response.status}`)
            }
        }
        catch(error){
            console.log(error)
        }
    }

    // const locations=[
    //     {
    //         id:1,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:2,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:3,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:4,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:5,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:6,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:7,
    //         place:'Adigrat'
    //     },
    //     {
    //         id:8,
    //         place:'Adigrat'
    //     }
    // ]
    return(
        <View style={Styles.container}>
            <View style={Styles.headerContainer}></View>
            <View style={Styles.placesContainer}>

                <View style={Styles.placesFormContainer}>
                    <TextInput
                        ref={PlaceInputRef}
                        style={Styles.placeInput}
                        placeholder="Place Name"
                        onChangeText={(text) => setPlaceName(text.charAt(0).toUpperCase() + text.slice(1))}
                    />
                    <TouchableOpacity style={Styles.submitButton} onPress={submitPlace}>
                        {addingPlace ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                    </TouchableOpacity>
                </View>

                {locations.length > 0 ? 
                    (
                    <ScrollView style={Styles.placesListContainer}>
                        <FlatList
                        data={locations}
                        keyExtractor={(item,index) => index.toString()}
                        renderItem={({ item,index }) => (
                            <ItemLister
                                item_id={index+1}
                                item_name={item.place_name}
                                icon_one={<AntDesign name="delete" size={16} color="red" />}
                            />
                        )}
                        />
                    </ScrollView>
                    )
                : (
                    <View style={Styles.dataMissingTextContainer}>
                        <Text style={Styles.displayText}>Empty list</Text>
                    </View>
                    )
                }
            </View>
        </View>
    )
}
const Styles = StyleSheet.create({
    container:{
        width:'100%',
        height:SCREEN_HEIGHT,
        backgroundColor:colors.white
    },
    headerContainer:{
        flexDirection:'row',
        justifyContent:'space-between',
        paddingHorizontal:19,
        paddingTop:30,
        paddingBottom:3,
    },
    placesContainer:{
        width:'23%',
        height:'50%',
        marginLeft:20,
        borderRadius:7,
        // backgroundColor:colors.ForeGroundWhite,
        shadowColor:colors.primary,
        elevation:4
    },
    placesFormContainer:{
        width:'100%',
        flexDirection:'row',
        paddingVertical:20,
        paddingHorizontal:7,
        backgroundColor:colors.primary,
        borderTopStartRadius:7,
        borderTopEndRadius:7,
    },
    placeInput:{
        flex:1,
        marginLeft:4,
        borderColor: colors.offwhite,
        borderWidth: 1,
        borderRadius: 5,
        paddingLeft: 7,
        // color:colors.white
        backgroundColor:colors.white
    },
    submitButton: {
        width:'25%',
        marginLeft: 7,
        backgroundColor: '#2196F3',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3
    },
    placesListContainer:{
        flex:1,
        flexDirection:'column',
        marginBottom:10,
        paddingBottom:10,
        borderBottomLeftRadius:7,
        borderBottomRightRadius:7,
        backgroundColor:colors.offwhite,
    },
    dataMissingTextContainer:{
        alignItems:'center',
        justifyContent:'center',
        flex:1,
    },
    listContainer:{
        flexDirection:'row',
        paddingHorizontal:7,
        backgroundColor:colors.white,
        marginVertical:2,
        marginHorizontal:4,
        paddingVertical:7
    },
    displayText:{
        fontSize: 15,
        fontWeight: '700',
        paddingRight:10,
        color: colors.Charcoal,
    },
})

import React from 'react'
import { Text, StyleSheet, TextInput, TouchableOpacity, View, Linking } from 'react-native';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import { MaterialCommunityIcons } from '@expo/vector-icons';

export default function ItemLister({item_id, item_name, icon_one, icon_two}){

    return(
        <View style={Styles.listContainer}>
            <View style={Styles.listRowContainer}>
                <Text style={Styles.listTextOne}>{item_id+`.`}</Text>
                <Text style={Styles.listTextTwo}>{item_name}</Text>
            </View>
            <View style={Styles.listRowContainer}>
                <TouchableOpacity>{icon_one}</TouchableOpacity>
                <TouchableOpacity>{icon_two}</TouchableOpacity>
            </View>
        </View>
    )
}
const Styles = StyleSheet.create({
    listContainer:{
        flexDirection:'row',
        justifyContent:'space-between',
        paddingHorizontal:7,
        backgroundColor:colors.white,
        marginVertical:2,
        marginHorizontal:4,
        paddingVertical:7
    },
    listRowContainer:{
        flexDirection:'row'
    },
    listTextOne:{
        fontSize: 15,
        fontWeight: '700',
        paddingRight:10,
        color: colors.Charcoal,
    },
    listTextTwo:{
        fontSize: 15,
        fontWeight: '700',
        color: colors.Charcoal,
    }
})